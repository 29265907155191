import React from 'react'
import car from '../images/clang.jpg'
import Radium, { StyleRoot } from 'radium';

const  Programming=()=> {
  return (
    <StyleRoot>
    <div>
       <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:1000,width:1400 }}>
    <h1 className="text-center">Programming</h1></div>
    </div>
    </StyleRoot>
  )
}
export default Radium(Programming);
