import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyB9m7J74XNDib3FUq-pIQb6a3Flvqnlt7I",
  authDomain: "hans-durgait.firebaseapp.com",
  databaseURL: "https://hans-durgait.firebaseio.com",
  projectId: "hans-durgait",
  storageBucket: "hans-durgait.appspot.com",
  messagingSenderId: "536454190754",
  appId: "1:536454190754:web:43e0ac7fc700a138bb29fb",
  measurementId: "G-ZK9WS8ZGG4"
  };
 const Config= firebase.initializeApp(firebaseConfig);
 export default Config;