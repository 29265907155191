import React from 'react'
import car from '../images/officems.jpg'
import Radium, { StyleRoot } from 'radium';

const  MsOffice=()=> {
  return (
    <StyleRoot>
    <>
     <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1500 }}>
    <h1 className="text-center">MsOffice</h1>
      

     
<div  className="text-center">

</div>
</div>
    
<div  className="text-center">
<h1> environment:</h1>
<h2>Create a new document.</h2>
<h2>Open, save and print a document.</h2>
<h2>Edit and format text.</h2>
<h2>Change the page layout, background and borders.</h2>
<h2>Insert headers and footers.</h2>
<h2>Insert and edit tables.</h2>
<h2>Insert clip art and pictures to documents.</h2>
<h2>Perform a mail merge.</h2>
<h2>Share and review shared document files.</h2>
<h1>Outline:</h1>

<h2>Word Basics</h2>
<h2>Work with Text</h2>
<h2>Format Documents</h2>
<h2>Work with Text Objects</h2>
<h2>Work with References</h2>
<h2>Work with Illustrations</h2>
<h2>Specialized Documents</h2>
<h2>Collaborate with Others</h2>
<h2>Web Pages</h2>
</div>
    </>
    </StyleRoot>
  )
}
export default Radium(MsOffice);
