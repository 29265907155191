import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import './IngredientList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../../../node_modules/react-bootstrap/Table';
import Radium, { StyleRoot } from 'radium';
// import Sidebar from '../../../sidebar/Sidebar.jsx';
// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


const IngredientList = props => {
  console.log('RENDERING INGREDIENTLIST');
  return (
    <StyleRoot>
    
    <>
      <h2>Loaded StudentList</h2>
      <Table striped bordered hover size="sm" style={{margin:20,paddingLeft:20,paddingRight:20}}>
      <thead className="thead-dark">
      <tr>
                    <th>fullname</th><br/>
                    <th>phone</th><br/>
                    <th>Email</th><br/>
                    <th>DOB</th><br/>
                    <th>education</th><br/>
                    
                    <th>Course</th><br/>
                   
                    
                    <th>address</th><br/>
                    
                </tr>
            </thead>
            <tbody>
        {props.ingredients.map(ig => (
          
          <tr key={ig.id}>  
          <td>{ig.title}</td><br/>
          <td> {ig.amount}</td><br/>
          <td> {ig.email}</td><br/>
          <td>{ig.dob}</td><br/>
          <td>{ig.education}</td><br/>
          <td> {ig.course}</td><br/>
          <td> {ig.address}</td><br/>
         <button onClick={props.onRemoveItem.bind(this, ig.id)} style={{color:'red'}}><FaTrashAlt/></button>
         </tr>
          

        ))}   </tbody></Table>
      
    </>
    </StyleRoot>
  );
};

export default Radium(IngredientList);
