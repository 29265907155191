import React, { Component } from 'react'
import logo from '../logo.svg';
import './SignIn.css';
import Authen from './Authen';
import Radium, { StyleRoot } from 'radium';
import Sidebar from '../sidebar/Sidebar.jsx'
import car from '../images/1.jpeg'
 class SignIn extends Component {
  render() {
    return (
      <StyleRoot>
      <Sidebar/>
      <div className="App" style={{  backgroundImage:`url(${car})` }}>
      <div className="App-header" style={{  backgroundImage:`url(${car})` }}>
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Welcome to <strong style={{color:'Highlight'}}>durgamaa.co.in</strong></h2>
      </div>
      <Authen />
    </div>
    </StyleRoot>
    )
  }
}
export default Radium(SignIn);