import React from 'react'
import './Ghost.css'
function Ghost() {
    return (
        <div>
            



<div className="sandbox">
 
  <div className="content">

   

    <section>

    

      <div className="path">
        <div className="pacman"></div>
        <div className="ghost">
          <div className="eyes"></div>
          <div className="skirt"></div>
        </div>
      </div>

    </section>

  </div>
</div>

<div className="final-result">
   
    <div className="content">

      <section>

       

        <div className="final__path">
          <div className="final__pacman"></div>
          <div className="final__ghost">
            <div className="final__eyes"></div>
            <div className="final__skirt"></div>
          </div>
        </div>

      </section>

    </div>
</div>

<div className="info">
 
</div>

        </div>
    )
}

export default Ghost
