import React from 'react'
import './Scroll.css';

function Scroll() {
    return (
        <div>
            



<div className="sandbox">

  <div className="content">

   

    <section>

      

      <div className="animation-bar-1">
        <span style={{width:60}}></span>
      </div>

    </section>

    <section>

      

      <div className="animation-bar-2">
        <span data-label="Loading"></span>
      </div>

    </section>

  </div>
</div>

<div className="final-result">
 
    <div className="content">

      <section>

       

        <div className="final__animation-bar-1">
          <span style={{width:60}}></span>
        </div>

      </section>

      <section>

        

        <div className="final__animation-bar-2">
          <span data-label=""></span>
        </div>

      </section>

    </div>
</div>


        </div>
    )
}

export default Scroll;
