import React from 'react'
import car from '../images/job5.jpg'
import Radium, { StyleRoot } from 'radium';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import aarchi from '../images/aarchi.jpg';
import harsh from '../images/harsh.jpg';
import lok from '../images/lokom.jpg';
import om1 from '../images/laxmi.jpg';
import om2 from '../images/saraswantmaa.jpg';
import om3 from '../images/trimurti.jpg';
import om4 from '../images/office5.jpg';
import ganeshji from '../images/ganesh.jpg';
import hanumanom from '../images/Hanuman-Chalisa-e1503166578190.jpg';


const  Slidejob=()=> {
  return (
    <StyleRoot>
    
    <div>
    
        <div>
     <h1  className="text-center" style={{color:'red'}}> 
     <marquee> Cg Jobs Comming Soon</marquee></h1>
    <h2  className="text-center"><strong style={{color:'lightpink'}}>durgamaa.co.in</strong></h2>
    <h4 className="text-center" style={{color:'Highlight'}}> </h4>
        {/* <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}>
    <h1 className="text-center"></h1>
    </div> */}
    

    <div className="slide-container">
        <Slide>
          <div className="each-slide">
         
            <div style={{'backgroundImage': `url(${car})`,height:600,width:1400}}>
              <span>Slide 1</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${harsh})`,height:600,width:1400}}>
              <span>Slide 2</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${lok})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
         
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${aarchi})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${ganeshji})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${hanumanom})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${om1})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${om2})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${om3})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${om4})`,height:600,width:1400}}>
              <span>Slide 3</span>
            </div>
          </div>
        </Slide>

      </div>
      </div>
    </div>
    <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5">
          DCA PgDca new batch comming soon with limited seats
</marquee></div>
    </StyleRoot>
  )
}
export default Radium(Slidejob);
