import React from 'react'
import car from '../images/carrer.jpg'
import Radium, { StyleRoot } from 'radium';


const  Career=()=> {
  return (
    <StyleRoot>
    <>
     <div>
     <h1  className="text-center" style={{color:'lightblue'}}> Careers</h1>
    <h2  className="text-center">Come Work With Us<strong style={{color:'lightpink'}}>durgamaa.co.in</strong></h2>
    <h4 className="text-center" style={{color:'Highlight'}}>Launch your own Website with durgamaa.co.in </h4>
        <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}>
    <h1 className="text-center"></h1>
    </div>
    </div>
    </>
    </StyleRoot>
  )
}
export default Radium(Career);
