import React from 'react'
import car from '../images/web.png'
import { NavLink } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';


const  Website=()=> {
  return (
    <StyleRoot>
    <div>
     <div>
    <NavLink to="/Register" className="btn-get-started" style={{margin:25,paddingLeft:25,color:'yellow'}} >enquiry form here</NavLink>
    </div>
     <h1  className="text-center" style={{color:'lightblue'}}> Made In India</h1>
    <h2  className="text-center">Publish Your Website With <strong style={{color:'lightpink'}}>durgamaa.co.in</strong></h2>
    <h4 className="text-center" style={{color:'Highlight'}}>Launch your own Website with durgamaa.co.in </h4>
        <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}>
    <h1 className="text-center">Website Devlopment</h1>
    </div>
    </div>
    </StyleRoot>
  )
}
export default Radium(Website);
