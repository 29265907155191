import React from 'react'
import om from '../images/librery2.jpg'
import Radium, { StyleRoot } from 'radium';


const  Tutorial=()=> {
  return (
    <StyleRoot>
    <>
    <div style={{  backgroundImage:`url(${om})`,margin:20,paddingLeft:20,height:800,width:1400 }}>
    <h2>visit youtube channel</h2>
   
      Tutorial
      <iframe width="560" height="315" src="https://www.youtube.com/embed/OVQ8FqfV-yA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      {/* <img src={om} className="img-fluid animated" alt="home img" style={{height:600,width:1500}} /> */}
    </div>
    </>
    </StyleRoot>
  )
}
export default Radium(Tutorial);
