import React ,{useEffect, useState}from 'react'
import car from '../images/bg.png'
import firebase from '../Config';
import Config from '../Config';
// import SimpleReactValidator from 'simple-react-validator';
// import useForm from 'react-hook-form';

var uuid = require('uuid');
const  SignUp=()=> {
 
  const [data,setData]= useState({
    fullname:'',
    phone:'',
    email:'',
    education:'',
    address:'',
    birth:'',
    
   
  })
 

  const createTodo = () => {
    const todoRef = firebase.database().ref("students-list").push(data);
   

    
  };


const InputEvent=(e)=>{
const {name,value}=e.target;
setData((preVal)=> {
  return{
         ...preVal,
         [name]:value,
  }
})
// setData({[e.target.name]:e.target.value});

}
  const formSubmit= (event)=>{
    event.preventDefault();
   
    alert('form submited sucessfully');
    // alert(`name ${data.fullname}.email ${data.email},phone ${data.phone},education ${data.education}, addresss ${data.address}`);

  }
  return (
    <>
    <div className="my-5">
    <h1 className="text-center">Please Reagister Here</h1>
    </div>
    <div className="container contact_div">

      <div className="row">

        <div className="col-md-6 col-10 mx-auto">
        <form onSubmit={formSubmit} style={{  backgroundImage:`url(${car})` }}>

        <div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">FullName</label>
  <input type="text" className="form-control" id="fullname" 
  name="fullname"
  value={data.fullname}
  onChange={InputEvent}
  refs="fullname"
  minLength='5'

  placeholder="enter your name" required/>

  
  
</div>

<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number</label>
  <input type="number" className="form-control" id="phone" 
  name="phone"
  value={data.phone}
  onChange={InputEvent}
  refs="phone"
  // minLength="10"
  // maxLength="12"
  min="1000000000"
  max="99999999999"
  
  
// phone="numeric"
  placeholder="mobile number"
  keyboardType="numeric"
  required
  />
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
  <input type="email" className="form-control" id="email" 
  name="email"
  value={data.email}
  onChange={InputEvent}
  refs="email"

  placeholder="name@example.com" required/>


</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">Education</label>
  <input type="text" className="form-control" id="education" 
  name="education"
  value={data.education}
  onChange={InputEvent}
  refs="education"
  minLength='4'
  maxLength='50'

  placeholder="enter your last qualification" required/>
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
  <input type="date" className="form-control" id="birth" 
  name="birth"
  value={data.birth}
  onChange={InputEvent}
  refs="birth"

  placeholder="enter your dob" required/>
</div>
<div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Address</label>
  <textarea className="form-control" id="address" rows="2"
   name="address"
   refs="address"
   minLength='10'
   maxLength='200'
   min="10"
   max="200"
   placeholder="enter your Address"
  value={data.address}
  onChange={InputEvent} required></textarea>
</div>
 <div className="col-12">
    <button className="btn btn-primary" type="submit" onClick={createTodo} >Submit form</button>
  </div>
        </form>

        </div>
      </div>
    </div>
    </>
  )
}
export default SignUp;
