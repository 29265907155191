import React ,{useEffect}from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './Home';
import About from './About';
import Notice from './Notice' 
import Contact from './Contact'
import Services from './Services';
import Devlopment from './Devlopment';
import Theory from './Component/Theory';
import Admission from './Component/Admission';
import AppDevloment from './Component/AppDevloment';
import Assignment from './Component/Assignment';
import Book from './Component/Book';
import Career from './Component/Career';
import Dca from './Component/Dca';
import ExamDetail from './Component/ExamDetail';
import Job from './Component/Job';
import Libraray from './Component/Libraray';
import MsOffice from './Component/MsOffice';
import PgDca from './Component/PgDca';
import Practicle from './Component/Practicle';
import Programming from './Component/Programming';
import Project from './Component/Project';
import Tally from './Component/Tally';
import Tutorial from './Component/Tutorial';
import Website from './Component/Website';
import SignIn from './Sign/SignIn';
import SignUp from './Sign/SignUp';
import Register from '../src/Sign/components/Ingredients/Form'
import Jobs from '../src/job/components/Ingredients/Ingredients'
import StudentList from '../src/Sign/components/Ingredients/Ingredients'
import Company from '../src/job/components/Ingredients/Form';
import Footer from './Component/footer/Footer';

import fire from './Config';
import Navbar from './Navbar';
import { Redirect, Route, Switch } from 'react-router-dom';


const  App=()=> {
//   useEffect(() => {
//     // Update the document title using the browser API
//  <fire/>
//   },[]);
  return (
    <>
    <Navbar/>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/About" component={About}/>
      <Route exact path="/Theory" component={Theory}/>
      <Route exact path="/Services" component={Services}/>
      <Route exact path="/Contact" component={Contact}/>
      <Route exact path="/Notice" component={Notice}/>
      <Route exact path="/Devlopment" component={Devlopment}/>
      <Route exact path="/Admission" component={Admission}/>
      <Route exact path="/AppDevloment" component={AppDevloment}/>
      <Route exact path="/Assignment" component={Assignment}/>
      <Route exact path="/Book" component={Book}/>
      <Route exact path="/Career" component={Career}/>
      <Route exact path="/Dca" component={Dca}/>
      <Route exact path="/ExamDetail" component={ExamDetail}/>
      <Route exact path="/Job" component={Job}/>
      <Route exact path="/Tally" component={Tally}/>
      <Route exact path="/Tutorial" component={Tutorial}/>
      <Route exact path="/Libraray" component={Libraray}/>
      <Route exact path="/MsOffice" component={MsOffice}/>
      <Route exact path="/PgDca" component={PgDca}/>
      <Route exact path="/Practicle" component={Practicle}/>
      <Route exact path="/Programming" component={Programming}/>
      <Route exact path="/Project" component={Project}/>
      <Route exact path="/Website" component={Website}/>
      <Route exact path="/SignIn" component={SignIn}/>
      <Route exact path="/SignUp" component={SignUp}/>
      <Route exact path="/Register" component={Register}/>
      <Route exact path="/StudentList" component={StudentList}/>
      <Route exact path="/Jobs" component={Jobs}/>
      <Route exact path="/Company" component={Company}/>


      <Redirect to="/"/>
    </Switch>
      <Footer/>
    </>
  )
}
export default App;
