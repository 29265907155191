import React from 'react'
import om from '../images/Presentation1.png'
import Radium, { StyleRoot } from 'radium';


const Practicle=()=> {
  return (
    <StyleRoot>
    <>
     <div className="my-5">
    <h1 className="text-center">Practicle class timing Here</h1>
    <h4 className="text-center">
Monday—Friday: 9:00AM–5:00PM</h4>
<h5 className="text-center">
Saturday & Sunday: 11:00AM–3:00PM</h5>
<img src={om} className="img-fluid animated" alt="home img" style={{height:800,width:1500}} />


Search
Search …

    </div>
    </>
    </StyleRoot>
  )
}
export default Radium(Practicle);
