import React from 'react'
import car from '../images/librery2.jpg'

const  ExamDetail=()=> {
  return (
    <div>
        {/* <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20 }}> */}
    <h1 className="text-center">Exam Detail </h1>
    <div>
        <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}></div>
    </div>
    </div>
  )
}
export default ExamDetail;
