import React, { Component } from "react";
var firebase = require('firebase');

 class Footer1 extends Component {
  logout(){

 
     firebase.auth().signOut();
     var lout = document.getElementById('logout');

     //Write a thanks message for user
     lout.classList.add('hide');
   

  }
  render(){
  return (
   <>
    
      <div className="footer-copyright text-center py-3">
      <button onClick={this.logout} id="logout" className="hide" style={{color:'Highlight'}}>Log out</button>
          &copy; {new Date().getFullYear()} Copyright: <a href="https://www.ebandhu.in"> ebandhu.in </a>
        
      </div>
   </>
  );
}
}

export default Footer1;