// 

import React from 'react';
import Radium, { StyleRoot } from 'radium';
import car from './images/librery2.jpg'
import './Common.css';
import '../src/job/components/Ingredients/Form.css'
import { GiStopwatch } from "react-icons/gi";
import Sidebar from './sidebar/Sidebar';
import Ghost from './ghost/Ghost.jsx'


import { FaPhoneAlt,FaHome} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";


// import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

const  Contact=()=> {
  return (
    <StyleRoot>
    <Sidebar/>
    <>
    <div className="sepias" style={{  backgroundImage:`url(${car})`,margin:40,paddingLeft:100 }}>
    <div style={{backgroundColor:'white',fontFamily: 'Pacifico'}}>  <h1 style={{color:'green',fontWeight:"bold",backgroundColor:'white'}}>
Address:<FaHome/></h1>
<h1 style={{backgroundColor:'white',fontFamily: 'Pacifico'}} className="om2">HANS     COMPUTER EDUCATION CENTERE :</h1>
<h2 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue' ,textAlign:'center'}}>in front of Gramin bank Kolar Chouck,Abhanpur </h2>
<h4 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>RAIPUR (C.G) CONTACT NO-<FaPhoneAlt/>9977577342</h4>
<h2 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>email: <AiOutlineMail/>  loknathwelfare@gmail.com</h2><br/>
<h4  style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>App & Web Devlopment:<FaPhoneAlt/>9329696045 </h4>
<h3 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>
Hours<GiStopwatch/></h3>
<h4 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>
Monday—Friday: 9:00AM–5:00PM</h4>
<h5 style={{backgroundColor:'white',fontFamily: 'Pacifico',color:'blue',textAlign:'center'}}>
Saturday & Sunday: 11:00AM–3:00PM</h5>


Search
Search …
</div>
<div className="google-map-code">
  
<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d119062.14403566961!2d81.5843742406053!3d21.16465212529905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3a28ddf5a8a80df9%3A0xa94fb00421c3ca61!2sLaxmi%20Medical%20Store%2C%20Ghadi%20Chowk%2C%20Moudhapara%2C%20Raipur%2C%20Chhattisgarh%20492002!3m2!1d21.2456636!2d81.6411978!4m5!1s0x3a28da9cebf2a297%3A0x20d5227657ddad75!2sCHHATTISGARH%20RAJYA%20GRAMIN%20BANK%20KOLAR%2C%20Kolar%20Rd%2C%20Kolar%2C%20Chhattisgarh%20493661!3m2!1d21.0834294!2d81.6599712!5e0!3m2!1sen!2sin!4v1601974426206!5m2!1sen!2sin"
 style={{width:"900", height:"1550", frameborder:"0", border:0,allowfullscreen:"", ariaHidden:"false", tabindex:"0"}}></iframe>
        </div></div>
    </>
    <Ghost/>
    </StyleRoot>
  )
}
export default Radium(Contact);
