import React, { Component } from 'react'
import './Sidebar.css'
export default class Sidebar extends Component {
    render() {
        return (
            <div>
            

<div className="sticky-sidebar-lesson">
  

  <div className="sandbox">
  
    <div className="content">

   
    </div>
  </div>

  <div className="sticky-sidebar">
    <h4>(Domain+website+Hosting+Reactjs) with 99.9% Discount</h4>
     <p  style={{textAlign:"right"}}         >Only 1000 Rs.</p>
  </div>
</div>


 

            </div>
        )
    }
}
