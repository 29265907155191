import React, { useState } from 'react';

import { GoRepoPush} from "react-icons/go";

import Card from '../UI/Card';
import LoadingIndicator from '../UI/LoadingIndicator';
import './IngredientForm.css';

import '../../../Common.css';
import Radium, { StyleRoot } from 'radium';

const IngredientForm = React.memo(props => {
  const [enteredTitle, setEnteredTitle] = useState('');
  const [enteredAmount, setEnteredAmount] = useState('');
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredEducation, setEnteredEducation] = useState('');
  const [enteredDob, setEnteredDob] = useState('');
  const [enteredAddress, setEnteredAddress] = useState('');
  const [enteredCourse, setEnteredCourse] = useState('');
  console.log('RENDERING INGREDIENT FORM');

  const submitHandler = event => {
    event.preventDefault();
    alert('form submited sucessfully');
    props.onAddIngredient({ title: enteredTitle, amount: enteredAmount ,email:enteredEmail,education:enteredEducation,dob:enteredDob,course:enteredCourse,address:enteredAddress});
   
  };

  return (
    <StyleRoot>
    <section className="ingredient-form">
      <Card>
        <form onSubmit={submitHandler}>
          <div className="form-control">
            <label htmlFor="title">
            Job Type</label>
            <input
              type="text"
              id="title"
              value={enteredTitle}
              onChange={event => {
                setEnteredTitle(event.target.value);
              }}
              minLength="4"
              maxLength="50"
              required
            />
          </div>
          <div className="form-control">
            <label htmlFor="amount"> Comany phone</label>
            <input
              type="number"
              id="amount"
              value={enteredAmount}
              onChange={event => {
                setEnteredAmount(event.target.value);
              }}
              min="1000000000"
              max="999999999999"
              required
            />
             </div>
          <div className="form-control">
            <label htmlFor="email"> Company Email</label>
            <input
              type="email"
              id="email"
              value={enteredEmail}
              onChange={event => {
                setEnteredEmail(event.target.value);
              }}
              required
            />
             </div>
          <div className="form-control">
            <label htmlFor="dob"> Company Job Show Date on Web</label>
            <input
              type="date"
              id="dob"
              value={enteredDob}
              onChange={event => {
                setEnteredDob(event.target.value);
              }}
              required
            />
             </div>
          <div className="form-control">
            <label htmlFor="education">Company Name</label>
            <input
              type="text"
              id="education"
              value={enteredEducation}
              onChange={event => {
                setEnteredEducation(event.target.value);
              }}
              minLength="4"
              maxLength="40"
              required
            />
             </div>
          <div className="form-control">
            <label htmlFor="Course">Eligibility For Employee</label>
            <input
              type="Text"
              id="course"
              value={enteredCourse}
              onChange={event => {
                setEnteredCourse(event.target.value);
              }}
              minLength="2"
              maxLength="20"
              required
            />
             </div>
          <div className="form-control">
            <label htmlFor="address">Job Location</label>
            <input
              type="TextArea"
              id="address"
              value={enteredAddress}
              onChange={event => {
                setEnteredAddress(event.target.value);
              }}
              required
            />
          </div>
          <div className="ingredient-form__actions">
            <button type="submit" className="w3-button w3-white w3-border w3-border-red w3-round-large"  ><GoRepoPush/>Submit</button>
            {props.loading && <LoadingIndicator />}
          </div>
        </form>
      </Card>
    </section>
    </StyleRoot>
  );
});

export default IngredientForm;
