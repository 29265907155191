import React from "react";
import "./Footer.css";
import {FaFacebook,FaTwitterSquare,FaInstagram,FaYoutubeSquare} from 'react-icons/fa';

import { SiCurl} from "react-icons/si";

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <h4>हंस  इंस्टिट्यूट</h4>
            <h1 className="list-unstyled" style={{fontFamily: 'Pacifico'}}>
              <li style={{fontFamily: 'Pacifico'}}><SiCurl/>durgamaa.co.in</li>
              <li >Kolar chouck, Raipur </li>
              <li >beside gramin bank</li>
            </h1>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Stuff</h4>
            <ui className="list-unstyled" style={{fontSize:29}}>
              <li>App Development</li>
              <li>Website Development </li>
              <li>DCA & PGDCA Course</li>
              <li>Tally & Programming Course</li>
            </ui>
          </div>
          {/* Column3 */}
          <div className="col">
            <h4>Connect With Us</h4>
            <ui className="list-unstyled">
              <li><a href="https://www.facebook.com/loknath.sahu.773"><FaFacebook/>Facebook</a></li>
              <li><a href="https://www.instagram.com/loknaths5/"><FaInstagram/> Instagram</a></li>
              <li><a href="https://twitter.com/lloknath"><FaTwitterSquare/> Twitter</a></li>
              <li><a href="https://www.youtube.com/channel/UCHhuRj6RI5_eeHKlE4-B-cw/?guided_help_flow=5"><FaYoutubeSquare/> YouTube</a></li>
            </ui>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} हंस  इंस्टिट्यूट| All rights reserved |
            Terms Of Service | Privacy
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
