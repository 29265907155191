import React from 'react'
import dca from '../pdf/DCA.pdf'
import pgdca from '../pdf/PGDCA.pdf'
import form1 from '../pdf/CVRUOpenExamForm.pdf'
import car from '../images/bg7.jpg'
import { NavLink } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';


// import { Document } from 'react-pdf' 
const  PgDca=()=> {
  return (
    <StyleRoot>
    <>
       <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20 }}>
    <h1 className="text-center">DCA</h1>
    <h2>DCA syllebus dowanload here  <a href = {dca} target = "_blank">Download dca.Pdf</a> </h2>

    <h3>PGDCA syllebus dowanload here  <a href = {pgdca} target = "_blank">Download pgdca.Pdf</a> </h3>
    <h4>Exam Form dowanload here  <a href = {form1} target = "_blank">Download examform.Pdf</a> </h4>


    <NavLink to="/Register" className="btn-get-started" style={{margin:25,paddingLeft:900,alignItems:'center',justifyContent:'center',fontSize:28,color:'lightgoldenrodyellow'}} >Register Here</NavLink>

    </div>
    </>
    </StyleRoot>
  )
}
export default Radium(PgDca);
