import React from 'react';
import Footer from './Footer1';
import car from '../src/images/bg7.jpg'

import { NavLink } from 'react-router-dom';
// import './Devlopment.css';
//Calling Bootstrap 4.5 css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//Calling Firebase config setting to call the data
import firebase from './Config';
class Devlopment extends React.Component {
constructor(props) {
    
    super(props);
   
    this.state = {studentslist : []}
    }
    
    logout(){

 
         firebase.auth().signOut();
     var lout = document.getElementById('logout');
    
        //  //Write a thanks message for user
         lout.classList.add('hide');
       
  
      }
  componentDidMount() {
   
   
     
      firebase.database().ref("students-list").on("value", snapshot => {
        let studentlist = [];
        snapshot.forEach(snap => {
            // snap.val() is the dictionary with all your keys/values from the 'students-list' path
            studentlist.push(snap.val());
        });
        this.setState({ studentslist: studentlist });
      });
    
    
 }
  
  render(){
  return (<>
  <div className="mt-3">
  <button viSit="/Service" onClick={this.logout} >Logout </button>
{/* <div><NavLink to="/Notice" className="btn-get-started" style={{margin:25,paddingLeft:25}} >Update and Delete</NavLink></div> */}
</div>
 
    <div className="MainDiv" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}>
    
      <div className="jumbotron text-center bg-sky">
          <h3>Candidate Details</h3>
      </div>
    
      <div className="container">
          <table id="example" className="display table">
            <thead className="thead-dark">
                <tr>
                    <th>fullname</th>
                    
                    
                    <th>education</th>
                    <th>phone</th>
                    <th>DOB</th>
                    <th>Email</th>
                    
                    <th>address</th>
                    
                </tr>
            </thead>
            <tbody>
            {this.state.studentslist.map(data => {
                
                return (
                    <tr>     
                    <td>{data.fullname}</td>
                    
                    
                    <td>{data.education}</td>
                    <td>{data.phone}</td>
                    <td>{data.birth}</td>
                    <td>{data.email}</td>
                    <td>{data.address}</td>
                    
                    </tr>
                    
                );
               
                })}
        
               
            </tbody>
            
         </table>
          
     </div>
     <Footer/>
    </div>
    </>
  );
}
}
export default Devlopment;