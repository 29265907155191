// 


import React from 'react'
import { NavLink } from 'react-router-dom';
import om from './images/about.jpg';
import web from './images/web.png';
import app from './images/app7.png';
import Common from './Common'
import Pyramid from './pyramid/Pyramid.jsx';
import Acord from './acord/Acord.jsx';
import Layer from './3d/layer/Layer.jsx';
import {makeStyles} from '@material-ui/core/styles';
import {Grid,Paper} from '@material-ui/core';
import Sliding from './slide/SlideJob.jsx';
const  usestyle=makeStyles((theme)=>({
  grid :{
    width:'100%',
    margin:'0px',
  },
  paper:{
    padding:theme.spacing(2),
    textAlign:"center",
    color:theme.palette.text.secondary,
    background:theme.palette.success.light,
    backgroundColor:'white',
    fontFamily: 'Roboto'
  }
  
  }));

const  About=()=> {
  const classes=usestyle();
  return (
    <>
    <Common name ="Hans Institute 5-year-old No1 Organization.  " 
    imgsrc={om} visit="/contact" btname=" Contact Now"
  />

<Grid container spacing={0} className={classes.grid}>
    <Grid item  xs={12} md={6}>
    <Paper className={classes.paper}>
<h1>Software to enhance your business</h1><br/>

The solutions you need to succeed.<br/>
The main thing about “DurgaIt Software Developers” is “Flexible” and that’s makes us Central India’s best choice in delivering perfection over any business challenges. We’re the team of logical thinkers which consist of 70% IITians & NITians. We can code in any programming language, any platform that gonna suits BEST as per your BUSINESS and that makes us BEST IT Company.</Paper>

    </Grid>
    <Grid item xs={12} md={6} >
    
<img src={web} style={{heigt:680,width:680}}/>


   </Grid>
   <Grid item  xs={12} md={6}>
   <img src={app} style={{heigt:100,width:674}}/>
    </Grid>
    <Grid item xs={12} md={6} >
    <Paper className={classes.paper}><h1>Software Perfection </h1><br/>
“NEXT IT REVOLUTION”<br/>
<br/>
DurgIt Software Developers, part of DurgaIt Software Foundation are helping millions of small business to become digital. DurgaIt Software is running numbers of innovative graduate minds working on Delivering Perfection over Information Technology world. DurgaIt Software assures to provide Perfection Grade Software & websites starting from ₹4999 for startups, small businesses & corporates. We do support the social causes issues and provide FREE software & sites development to them.
<br/>
<br/>
<h1></h1>


</Paper>

   </Grid>
  
    </Grid>

      
<div>
  <Pyramid/>
  <Acord/>
</div>
<div>
  <Layer/>
</div>
<Grid container spacing={0} className={classes.grid}>
<Grid item xs={12} md={12} >
     <Sliding/>
   </Grid></Grid>
{/* <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5" style={{fontFamily: 'Roboto'}}>
          DCA PgDca new batch comming soon with limited seats
</marquee></div> */}

    </>
  )
}
export default About;
