import React from 'react'
import Radium, { StyleRoot } from 'radium';

const  Assignment=()=> {
  return (
    <StyleRoot>
    <>
    <div className="container-fluid nav bg">
        <div className='row'>
        <div className="col-10 mx auto">

       
<div style={{margin:20,paddingLeft:20}}>
<h1>DCA 1st Semester</h1>
	<h2>	Fundamentals Of Computers & Information Technology</h2>
  <h2>Operating Systems(DOS, Windows, Linux)</h2>
  <h2>Computers In Office–I (MS Word & MS Excel)</h2>
  <h2>Computers In Office–II (MS PowerPoint, MS Access &  MS Outlook</h2>
    <h2>Communication Skills & Personality Development</h2>

<h1>2nd Semester</h1>

<h2>	Introduction To Internet & Web Technology</h2>
<h2>Introduction To Financial Accounting With Tally</h2>
<h2>Programming With Visual Basic.Net</h2>
<h2>Database Handling With FoxPro</h2>

</div>



<div style={{margin:20,paddingLeft:20}}>

<h1>PGDCA	1st Semester</h1>
<h2>	Fundamentals Of Computers & Information Technology</h2>
<h2>Windows & MS Office</h2>
<h2>Programming Methodology & Programming In Foxpro</h2>
<h2>Computer Networks & Internet</h2>
<h2>Communication Skills & Personality Development</h2>
<h1>2nd Semester</h1>	
<h2>Objects Oriented Programming With C++</h2>
<h2>DBMS & SQL (With MS Access / MS SQL Server)</h2>
<h2>Computerised Accounting With Tally</h2>
<h2>Programming With VB.Net</h2>

</div>


        <div className="my-5" style={{  backgroundImage:`url(${'https://i.ibb.co/8KPyn0h/lokom.jpg'})`,margin:20,padding:20,height:800,width:1400 }}></div>
        </div>

</div>

</div>
    </>
    </StyleRoot>
  )
}
export default Radium(Assignment);
