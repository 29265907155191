// import React from 'react'
// import { NavLink } from 'react-router-dom';
// import om from './images/9.png'
// import Common from './Common'
// import Radium, { StyleRoot } from 'radium';
// import car from './images/bg.png'
// const  Home=()=> {
//   return (
//     <StyleRoot>
//     <>
//   <Common name ="Grow your skill with " 
//     imgsrc={om} visit="/Services" btname=" Get Started"
    
//   />
//   <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5">
//           DCA PgDca new batch comming soon with limited seats
// </marquee></div>
//     </>
//     </StyleRoot>
//   )
// }
// export default Radium(Home);


import React from 'react'
import { NavLink } from 'react-router-dom';
import om from './images/9.png'
import Common from './Common'
import Radium, { StyleRoot } from 'radium';
import car from './images/bg.png'
import Slide from './slide/Slide.jsx';
const  Home=()=> {
  return (
    <StyleRoot>
    
    <>
  <Common name ="Grow your skill with " 
    imgsrc={om} visit="/Services" btname=" Get Started"
    
  />
  
  

    </>
<div>
  <Slide/>
</div>
    <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5" style={{fontFamily: 'Roboto'}}>
          DCA PgDca new batch comming soon with limited seats
</marquee></div>
    </StyleRoot>
  )
}
export default Radium(Home);
