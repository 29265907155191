import React from 'react'
import './Layer.css';
import Back from '../img/back.svg'
import Mid from '../img/mid.svg'
import Top from '../img/top.svg'
import Tip from '../img/tippytop.svg'
function Layer() {
    return (
        <div>
            


            {/* <h1>Day #26 3D Layers</h1> */}



<div className="sandbox">
  {/* <h2>Sandbox This is where you play</h2> */}
  <div className="content">

    {/* <p><strong>Instructions:</strong> Create a 3D layer effect with CSS3!</p> */}

    <section>

      {/* <h4>3D Layer Effect</h4> */}

      <div className="layers">
        {/* <!-- UNCOMMENT THIS
        <img src="img/back.svg" alt="back layer" className="back">
        <img src="img/mid.svg" alt="mid layer" className="mid">
        <img src="img/top.svg" alt="top layer" className="top">
        <img src="img/tippytop.svg" alt="tippy top layer" className="tippy">
        --> */}
      </div>

    </section>

  </div>
</div>

<div className="final-result">
    {/* <h2>Final Result Use this for reference!</h2> */}
    <div className="content">

      <section>

        {/* <h4>3D Layer Effect</h4> */}

        <div className="final__layers">
          <img src={Back} alt="back layer" className="back"/>
          <img src={Mid} alt="mid layer" className="mid"/>
          <img src={Top} alt="top layer" className="top"/>
          <img src={Tip} alt="tippy top layer" className="tippy"/>
        </div>

      </section>

    </div>
</div>

        </div>
    )
}

export default Layer
