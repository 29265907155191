import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';
import om from '../src/images/4.png';
const Card =(props)=> {
    
        return (
           
            <>
      

              
<div className="col-md-4 col-10 mx-auto">

<div className="card">
<img src={props.imgsrc} className="card-img-top" alt={props.imgsrc} style={{height:170,width:320}}/>
<div className="card-body">
<h5 className="card-title font-weight-bold">{props.title}</h5>
<p className="card-text">People need training and support throughout their careers, both as individuals and as teams, to develop their skills and continue to work effectively.</p>
<NavLink to="" className="btn btn-primary">Go somewhere</NavLink>
</div>
</div>
</div>
        

            </>
         
        )
    }


export default Card;
