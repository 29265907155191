import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import './IngredientList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from '../../../../node_modules/react-bootstrap/Table';
import Radium, { StyleRoot } from 'radium';
// import Sidebar from '../../../sidebar/Sidebar.jsx'


// import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


const IngredientList = props => {
  console.log('RENDERING INGREDIENTLIST');
  return (
    <StyleRoot>
    
    <>
      <h2 style={{color:'darkgoldenrod',backgroundColor:'#ffff'}}>Loaded Jobs in CG</h2>
      <Table striped bordered hover size="sm" style={{margin:20,paddingLeft:20,paddingRight:20}}>
      <thead className="thead-dark">
      <tr>
                    <th>Job Type</th><br/>
                    <th>Company phone</th><br/>
                    <th>Email</th><br/>
                    <th>Job on Web</th><br/>
                    <th>Company Name</th><br/>
                    
                    <th>Eligibility</th><br/>
                   
                    
                    <th>Job Location</th><br/>
                    
                </tr>
            </thead>
            <tbody>
        {props.ingredients.map(ig => (
          
          <tr key={ig.id}>  
          <td>{ig.title}</td><br/>
          <td> {ig.amount}</td><br/>
          <td> {ig.email}</td><br/>
          <td>{ig.dob}</td><br/>
          <td>{ig.education}</td><br/>
          <td> {ig.course}</td><br/>
          <td> {ig.address}</td><br/>
         <label onClick={props.onRemoveItem.bind(this, ig.id)}>.</label>
         </tr>
          

        ))}   </tbody></Table>
      
    </>
    <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5">
          DCA PgDca new batch comming soon with limited seats
</marquee></div>
    </StyleRoot>
  );
};

export default Radium(IngredientList);
