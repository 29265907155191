import React from 'react'

const  Project=()=> {
  return (
    <div>
      <div>
       <div className="my-5" style={{  backgroundImage:`url(${'https://i.ibb.co/CJZyHkd/prj2.jpg'})`,margin:50,padding:50,height:700,width:1000}}>
    <h1 className="text-center"></h1></div>
    </div>
    </div>
  )
}
export default Project;
