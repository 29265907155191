import React from 'react'
import './Acord.css';
function Acord() {
    return (
        <div>
            



<div className="sandbox">

  <div className="content">


    <section>

     

      {/* <div className="accordion">
        <div className="accordion__item">
          <a href="#tab1" className="accordion__trigger accordion__title">Accordion 1</a>
          <div id="tab1" className="accordion__content"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor laborum, rerum quasi rem placeat quo ratione qui, optio id eaque laudantium reprehenderit aliquam vel cum necessitatibus ut doloremque ullam natus.</p></div>
        </div>
        <div className="accordion__item">
          <a href="#tab2" className="accordion__trigger accordion__title">Accordion 2</a>
          <div id="tab2" className="accordion__content"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor laborum, rerum quasi rem placeat quo ratione qui, optio id eaque laudantium reprehenderit aliquam vel cum necessitatibus ut doloremque ullam natus.</p></div>
        </div>
        <div className="accordion__item">
          <a href="#tab3" className="accordion__trigger accordion__title">Accordion 3</a>
          <div id="tab3" className="accordion__content"><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor laborum, rerum quasi rem placeat quo ratione qui, optio id eaque laudantium reprehenderit aliquam vel cum necessitatibus ut doloremque ullam natus.</p></div>
        </div>
      </div> */}

    </section>

  </div>
</div>

<div className="final-result">
    <div className="content">

      <section>

        <h4>About Our Company</h4>

        <div className="final__accordion">
          <div className="accordion__item">
            <a href="#final__tab1" className="accordion__trigger accordion__title">Teaching</a>
            <div id="final__tab1" className="accordion__content"><p>Our mission at Hans Institute of Technology is to provide good standerd of learning, To provide conductive environment for focused learning, and to create excellence in knowledge and to enable its students to take best advantages of their educational opportunities. Hans Institute seeks to identify and remove restraints on student's learning process, so that students may explore their capabilities and interests and develop their potential. And to provide conductive environment for focused learning. Not only we are focusing on theoratical knowledge, we also help our candidates to have good practical knowledge.</p></div>
          </div>
          <div className="accordion__item">
            <a href="#final__tab2" className="accordion__trigger accordion__title">Skill</a>
            <div id="final__tab2" className="accordion__content"><p>Our team has been carefully selected for their dedication to pursuing. Our vision that all children in India will attain an excellent education will require us to have an exceptional team.</p></div>
          </div>
          <div className="accordion__item">
            <a href="#final__tab3" className="accordion__trigger accordion__title">Web Devlopment</a>
            <div id="final__tab3" className="accordion__content"><p>Web development is the work involved in developing a Web site for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex Web-based Internet applications, electronic businesses, and social network services.</p></div>
          </div>
        </div>

      </section>

    </div>
</div>
        </div>
    )
}

export default Acord;
