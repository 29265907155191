import React from 'react'
import './Slide.css';
import web from '../images/web.png';
import app from '../images/app5.png';
import dca from '../images/dca.png';
import institute from '../images/app7.png';
import {makeStyles} from '@material-ui/core/styles';
import {Grid,Paper} from '@material-ui/core';
const  usestyle=makeStyles((theme)=>({
  grid :{
    width:'100%',
    margin:'0px',
  },
  paper:{
    padding:theme.spacing(2),
    textAlign:"center",
    color:theme.palette.text.secondary,
    background:theme.palette.success.light,
    backgroundColor:'white',
    fontFamily: 'Roboto'
  }
  
  }));

function Slide() {
  const classes=usestyle();
    return (
        <>

<div className="final-result">
     
        <div className="content">

          
        <Grid container spacing={0} className={classes.grid}>
    <Grid item  xs={12} md={6}>
    <Paper className={classes.paper}>
            <h1 style={{textAlign:'center',fontFamily: 'Roboto'}}>
Software : ₹ 999
Software & Website Development starts from ₹999 .No hidden charges</h1>
</Paper></Grid>
<Grid item  xs={12} md={6}>
            <div className="final__slide slide-top">
              <div className="final__slide-content" 
              style={{backgroundColor:'white',fontFamily: 'Roboto',color:'blue',textAlign:'center',fontSize:50}}
              >Improving productivity with digital field workforce management.</div>
              <img src={institute} style={{height:400,width:700}}/>
            </div>

            </Grid>        

          
            <Grid item  xs={12} md={6}>
    <Paper className={classes.paper}>
            <h1 style={{textAlign:'center' ,fontFamily: 'Roboto'}}>Our mission at Hans Institute of Technology is to provide good standerd of learning, To provide conductive environment for focused learning.</h1>
            </Paper></Grid>
            <Grid item  xs={12} md={6}>
            <div className="final__slide slide-right">
              <div className="final__slide-content" style={{backgroundColor:'white',fontFamily: 'Roboto',color:'blue',textAlign:'center',fontSize:29}}>Our mission at Hans Institute of Technology is to provide good standerd of learning, To provide conductive environment for focused learning, and to create excellence in knowledge and to enable its students to take best advantages of their educational opportunities. Hans Institute seeks to identify and remove restraints on student's learning process, so that students may explore their capabilities and interests and develop their potential. And to provide conductive environment for focused learning. Not only we are focusing on theoratical knowledge, we also help our candidates to have good practical knowledge.</div>
              <img src={dca}  style={{height:600,width:700}}/>
            </div>

         
</Grid>
          


          <Grid item  xs={12} md={6}>
    <Paper className={classes.paper}>
            <h1 style={{textAlign:'center',fontFamily: 'Roboto'}}>Your dream, our work! We will provide you websites or applications with best creative designing.</h1>
            </Paper></Grid>
            <Grid item  xs={12} md={6}>
            <div className="final__slide slide-bottom">
              <div className="final__slide-content" style={{backgroundColor:'white',fontFamily: 'Roboto',color:'blue',textAlign:'center',fontSize:50}}>Web development is the work involved in developing a Web site for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex Web-based Internet applications, electronic businesses, and social network services.</div>
              <img src={web}  style={{height:600,width:700}}/>
            </div>

         </Grid>

          
         <Grid item  xs={12} md={6}>
    <Paper className={classes.paper}>
            <h1 style={{textAlign:'center',fontFamily: 'Roboto'}}>Our App Development team is there to make an app as per your requirements.</h1>
            </Paper></Grid>
            <Grid item  xs={12} md={6}>
            <div className="final__slide slide-left">
              <div className="final__slide-content"  style={{backgroundColor:'white',fontFamily: 'Roboto',color:'blue',textAlign:'center',fontSize:50}}>Our team has been carefully selected for their dedication to pursuing. Our vision that all children in India will attain an excellent education will require us to have an exceptional team.</div>
              <img src={app}  style={{height:600,width:700}}/>
            </div>

         </Grid>
</Grid>
        </div>
    </div>







        </>
    )
}

export default Slide;
