import React from 'react'
import './Pyramid.css';
function Pyramid() {
    return (
        <div>
              



<div className="sandbox">

  <div className="content">



    <section>

      <div className="triangle">

        <div className="top"></div>

        <div className="mid"></div>

        <div className="bottom"></div>

      </div>

    </section>

  </div>
</div>

<div className="final-result">
  
    <div className="content">

      <section>

        <div className="final__triangle">

          <div className="top">DurgaIt(No1)</div>

          <div className="mid">  Universities and Institutes</div>

          <div className="bottom">Private Institute</div>

        </div>

      </section>

    </div>
</div>

        </div>
    )
}

export default Pyramid
