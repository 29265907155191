import React from 'react'
import tally from '../pdf/tally.pdf'
import tally1 from '../images/tally.png'
import Radium, { StyleRoot } from 'radium';


const  Tally=()=> {
  return (
    <StyleRoot>
    <div>
      <div className="my-5">
    <h1 className="text-center">Tally</h1></div>
    <h3>Tally syllebus dowanload here  <a href = {tally} target = "_blank">Download tally.Pdf</a> </h3>
    <img src={tally1} className="img-fluid animated" alt="home img" style={{height:800,width:1500}} />
    </div>
    </StyleRoot>
  )
}
export default Radium(Tally);
