import React from 'react'
import { NavLink } from 'react-router-dom';
import car from '../images/librery.jpg'
import cs from '../pdf/book/16. Computer Book PDF.pdf'
import Radium, { StyleRoot } from 'radium';

import cs1 from '../pdf/book/Pariksha Manthan Computer Ek Parichay Book.pdf'

const  Libraray=()=> {
  return (
    <StyleRoot>
    <div>
            <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1500 }}>
    <h1 className="text-center"> book</h1>
    <h2>DCA syllebus dowanload here  <a href = {cs} target = "_blank">Download computerBook.Pdf</a> </h2>

    <h3>PGDCA syllebus dowanload here  <a href = {cs1} target = "_blank">Download PgDcaBook.Pdf</a> </h3>
    

    <NavLink to="/Register" className="btn-get-started" style={{margin:25,paddingLeft:900,alignItems:'center',justifyContent:'center',fontSize:28,color:'lightgoldenrodyellow'}} >Register Here</NavLink>
    </div>
    
    </div>
    </StyleRoot>
  )
}
export default Radium(Libraray);
