// 



import React from 'react'
import { NavLink } from 'react-router-dom';
import car from './images/bg5.jpg'
import Radium, { StyleRoot } from 'radium';
import Ghost from './ghost/Ghost.jsx'
import './Common.css';

const  Common=(props)=> {
  return (
    
    <><StyleRoot>
    <header>
      <h1 className="om2"> Welcome Hans Education &  Software Company</h1>
    </header>
      <section id="header" className="d-flex align-items-center" style={{  backgroundImage: `url(${car})` }} >
      <div className="container-fluid ">
        <div className='row'>
        <div className="col-10 mx auto">
        <div className="row">
        <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
        <h1 style={{color:"blue",backgroundColor:'white',margin:20,paddingLeft:20}} >{props.name}durgamaa.co.in </h1>
<h2 className="my-3" style={{color:"blue",margin:20,paddingLeft:20,backgroundColor:'white'}} >We provide best teaching skill & software Development</h2>
<div className="mt-3">
<NavLink to={props.visit} className="om" style={{margin:25,paddingLeft:25}} title="Page is Importent">{props.btname}</NavLink>
</div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 header-img">
<img src={props.imgsrc} className="img-fluid animated" alt="home img" />
        </div>
        </div>
      
        </div>

        </div>

        </div>

      </section>
      <Ghost/>
      </StyleRoot>
    </>
    
  )
}
export default Radium(Common);
