
import web from './images/web.png'
import app from './images/app5.png'
import digt from './images/digital.jpg'
import dca from './images/dca.png'
import marketing from './images/marketing.png'
import teaching from './images/teaching.jpg'
const Sdata= [
{
    imgsrc:web,
    title:'web devlopment',

},
{
    imgsrc:app,
    title:' app devlopment',

},
{
    imgsrc:dca,
    title:'Dca and PgDca Course',

},
{
    imgsrc:marketing,
    title:'Marketing',

},
{
    imgsrc:teaching,
    title:'programming Classes',

},
{
    imgsrc:digt,
    title:'digital marketing',

},

];
export default Sdata;