import React from 'react'
import { NavLink } from 'react-router-dom';
import Logo from './images/DurgaIt4.png'
import { SiMicrosoftoffice} from "react-icons/si";
import { MdAssignment,MdSearch } from "react-icons/md";
import { AiOutlineFundProjectionScreen,AiOutlineGlobal} from "react-icons/ai";


import {GrServices ,GrYoutube,GrPersonalComputer,GrCode,GrRun} from "react-icons/gr";
import {FaLightbulb,FaBook,FaOdnoklassniki,FaTumblrSquare,
  FaRegCalendar,FaRegAddressBook,FaAppStore,

  FaHandHoldingUsd,FaBell,FaBookReader, FaHome ,FaInfoCircle,FaPhoneVolume,FaUserPlus,FaSignInAlt} from 'react-icons/fa';


const  Navbar=() => {
    return (
        <>
        <div className="container-fluid nav-bg" style={{backgroundColor:'green',color:'green'}} >
        <div className="row" >
        <div className="col-10 mx-auto" >
            
        
            <nav className="navbar navbar-expand-lg navbar-light bg-light " >
  <div className="container-fluid">
    <NavLink  exact className="navbar-brand" to="/"><img src={Logo} style={{height:100,width:100,borderTopRightRadius:40,borderBottomRightRadius:40,WebkitBorderBottomLeftRadius:40}}/></NavLink>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">

    <li className="nav-item">
          <NavLink activeClassName="menu_active" exact className="nav-link active" aria-current="page" to="/" style={{fontFamily: 'Roboto'}}>Home<FaHome/></NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="menu_active" className="nav-link" to="/About" style={{fontFamily: 'Roboto'}}>About<FaInfoCircle/></NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="menu_active" className="nav-link" to="/Contact" style={{fontFamily: 'Roboto'}}>Contact us<FaPhoneVolume/></NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="menu_active" className="nav-link" to="/Register" style={{fontFamily: 'Roboto'}}>Register<FaUserPlus/></NavLink>
        </li>
        <li className="nav-item">
          <NavLink activeClassName="menu_active" className="nav-link" to="/SignIn" style={{fontFamily: 'Roboto'}}>SignIn<FaSignInAlt/></NavLink>
        </li>
        

      <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
       
        
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" style={{fontFamily: 'Roboto'}}>
            Services<GrServices/>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
          <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Services" style={{color:'#00ff00',fontFamily: 'Roboto'}}><GrServices/>Services*</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Theory"  style={{fontFamily: 'Roboto'}}><FaLightbulb/>Theory</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Libraray" style={{fontFamily: 'Roboto'}}><FaBook/>Library</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Practicle" style={{fontFamily: 'Roboto'}}><FaOdnoklassniki/>Practicle</NavLink></li>
           
            <li><hr className="dropdown-divider"/></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Tutorial" style={{fontFamily: 'Roboto'}}><GrYoutube/>Tutorial Class</NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" style={{fontFamily: 'Roboto'}}>
            Course<FaBookReader/>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Dca" style={{fontFamily: 'Roboto'}}><GrPersonalComputer/>Dca</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/PgDca" style={{fontFamily: 'Roboto'}}><GrPersonalComputer/>PgDca</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Tally" style={{fontFamily: 'Roboto'}}><FaTumblrSquare/>Tally</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/MsOffice" style={{fontFamily: 'Roboto'}}><SiMicrosoftoffice/>Ms-Office</NavLink></li>
            <li><hr className="dropdown-divider"/></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Programming" style={{fontFamily: 'Roboto'}}><GrCode/>Programming</NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" style={{fontFamily: 'Roboto'}}>
            Notice<FaBell/>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Admission" style={{fontFamily: 'Roboto'}}><FaRegCalendar/>Admission</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/ExamDetail" style={{fontFamily: 'Roboto'}}><FaRegAddressBook/>Exam Detail</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Assignment" style={{fontFamily: 'Roboto'}}><MdAssignment/>Assignment</NavLink></li>
            <li><hr className="dropdown-divider"/></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Project" style={{fontFamily: 'Roboto'}}><AiOutlineFundProjectionScreen/>Project</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Job" style={{fontFamily: 'Roboto'}}><FaBell/>Notice</NavLink></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false" style={{fontFamily: 'Roboto'}} >
            Product<FaHandHoldingUsd/>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/AppDevloment" style={{fontFamily: 'Roboto'}}><FaAppStore/>App Devlopment</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Website" style={{fontFamily: 'Roboto'}}><AiOutlineGlobal/>Website Devlopment</NavLink></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Career" style={{fontFamily: 'Roboto'}}><GrRun/>Career</NavLink></li>
           
            
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Company" style={{fontFamily: 'Roboto'}}><FaUserPlus/>Company Job Form*</NavLink></li>
            <li><hr className="dropdown-divider"/></li>
            <li><NavLink activeClassName="menu_active" className="dropdown-item" to="/Jobs" style={{fontFamily: 'Roboto'}}><MdSearch/>Jobs in c.g.</NavLink></li>
          </ul>
        </li>
      
      </ul>
      
    </div>
  </div>
</nav>
</div>
        </div>

        </div>
        </>
    )
}
export default Navbar;