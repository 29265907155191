import React, { Component } from 'react';
import StudentList from './components/Ingredients/Ingredients'
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';


var firebase = require('firebase');


// var config = {
//      apiKey: "AIzaSyBJaX5iNQqTzVFuXefmXaIxVUi5p25Y8n0",
//     authDomain: "ebandhu-7a8e5.firebaseapp.com",
//     databaseURL: "https://ebandhu-7a8e5.firebaseio.com",
//     projectId: "ebandhu-7a8e5",
//     storageBucket: "ebandhu-7a8e5.appspot.com",
//     messagingSenderId: "108608910998",
//     appId: "1:108608910998:web:1ea17a5f34a33ba17bb4b7",
//     measurementId: "G-H3HGQZ9NKN"
//   };
//   firebase.initializeApp(config);


class Authen extends Component {

  login(event){
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    console.log(email, password);

    const auth = firebase.auth();

    const promise = auth.signInWithEmailAndPassword(email, password);

    promise.then(user => {

      // return <Redirect to="/Devlopment" />

      var lout = document.getElementById('logout');


      //Write a welcome message for user
      lout.classList.remove('hide');


    });

    promise.catch(e => {
      var err = e.message;
      console.log(err);
      this.setState({err: err});
    });
  }

  signup(){
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    console.log(email, password);

    const auth = firebase.auth();

    const promise = auth.createUserWithEmailAndPassword(email, password);

    promise
    .then(user => {
      var err = "Welcome "+ user.email;
      firebase.database().ref('users/'+user.uid).set({
        email: user.email
      });
      console.log(user);
      this.setState({err: err});
    });
    promise
    .catch(e => {
      var err = e.message;
      console.log(err);
      this.setState(({err: err}));
    });
  }

  logout(){

 
    //  firebase.auth().signOut();
    // var lout = document.getElementById('logout');

    //  //Write a thanks message for user
    //  lout.classList.add('hide');
   
ReactDOM.render(
  <StudentList/>,
  document.getElementById('root'));
  }

  google(){
    console.log("I am in google method");

    var provider = new firebase.auth.GoogleAuthProvider();
    var promise = firebase.auth().signInWithPopup(provider);

    promise.then( result => {
      var user = result.user;
      console.log(result);
      firebase.database().ref('users/'+user.uid).set({
        email: user.email,
        name: user.displayName
      });

    });
    promise.catch(e => {
      var msg = e.message;
      console.log(msg);
    });

  }

  constructor(props){
    super(props);

    this.state = {
      err: ''
    };

    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
    this.google = this.google.bind(this);
  }

  render(){
    return(
      <StyleRoot>
      <div >
        <input id="email" ref="email" type="email" placeholder="Enter your email" /><br />
        <input id="pass" ref="password" type="password" placeholder="Enter your password" /><br />
        <p>{this.state.err}</p>
        <button onClick={this.login}>Admin Log In</button>
        {/* <button onClick={this.signup}>Sign Up</button> */}
        <button onClick={this.logout} id="logout" className="hide">Student List</button><br />
        <button onClick={this.google} id="google" className="google">Student Sign Up with Google</button>
       
      </div>
      <div style={{backgroundColor:'white',color:'red'}}>    <marquee  behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" scrollamount="5">
          DCA PgDca new batch comming soon with limited seats
</marquee></div>
      </StyleRoot>
    );
  }
}


export default Radium(Authen);
