import React from 'react'
import { NavLink } from 'react-router-dom';
import car from '../images/librery2.jpg'
import admission from '../pdf/Admission_form.pdf'
import Radium, { StyleRoot } from 'radium';


// import { Link } from "react-router-dom";
const  Admission=()=> {
  return (
    <StyleRoot>
  <>
 
    <div>
        {/* <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20 }}> */}
    <h1 className="text-center">Admission </h1>
    <div>
    
   
    <h2>Admission Form dowanload here  <a href = {admission} target = "_blank" style={{color:'red'}}>Download admissionForm.Pdf</a> </h2>

    
    

    <NavLink to="/SignUp" className="btn-get-started" style={{margin:25,paddingLeft:900,alignItems:'center',justifyContent:'center',fontSize:28,color:'lightgoldenrodyellow'}} >Register Here</NavLink>
    </div>
    
        <div className="my-5" style={{  backgroundImage:`url(${car})`,margin:20,padding:20,height:800,width:1400 }}></div>
    </div>
    </>
    </StyleRoot>
  )
}
export default Radium(Admission);
